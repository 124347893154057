import React, { useEffect } from "react";
import "./style.css";

import image from "../../../assets/images/start_iamge_1.png";
import { orangeOval } from "../../../assets/svgIcons";

import AOS from "aos";
import { arrowInblack } from "../../AboutUsPage/AboutBanner/AboutBanner";
import { FaWhatsapp } from "react-icons/fa6";

const Preview = ({ loading, homeData, siteInfo }) => {
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  // const handleScroll = () => {
  //   window.scrollBy({
  //     top: 500,
  //     left: 0,
  //     behavior: "smooth", // Optional for smooth scrolling
  //   });
  // };

  return (
    <div
      className="prev_container"
      style={{ backgroundImage: `url(${homeData?.banner_img})` }}
    >
      {/* <div className="scroll_arrow" onClick={() => handleScroll()}>
        {arrowInblack}
      </div> */}
      <div className="layout"></div>
      <div className="prev_content">
        <div className="image">
          <img src={image} alt="" />
        </div>
        <div className="">
          {/* {<div dangerouslySetInnerHTML={{ __html: homeData?.banner_txt }} />} */}
        </div>
        <div dangerouslySetInnerHTML={{ __html: homeData?.banner_title }} />

        <div className="oval_btns">
          {localStorage.getItem("petsUser") ? (
            <>
            <div
                className="oval_button"
                style={{
                  position: "relative",
                  fill: "rgb(248, 100, 8)",
                }}
              >
                {orangeOval}
                <div
                  className="oval_btn_text"
                  style={{whiteSpace:"nowrap", fontSize:"14px", padding:"4px 8px"}}
                  onClick={() => (window.location.href = "/registrar")}
                >
                  ¿Quiénes somos?
                </div>
              </div>

              <div
                className="oval_button"
                style={{
                  position: "relative",
                  fill: "#27b8c3",
                }}
                onClick={() => (window.location.href = "/login")}
              >
                {orangeOval}
                <div
                  className="oval_btn_text "
                  style={{ color: "#fff !important" }}
                >
                  Contacto
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="oval_button"
                style={{
                  position: "relative",
                  fill: "rgb(248, 100, 8)",
                }}
              >
                {orangeOval}
                <div
                  className="oval_btn_text"
                  onClick={() => (window.location.href = "/registrar")}
                >
                  Registrar...
                </div>
              </div>

              <div
                className="oval_button"
                style={{
                  position: "relative",
                  fill: "#27b8c3",
                }}
                onClick={() => (window.location.href = "/login")}
              >
                {orangeOval}
                <div
                  className="oval_btn_text "
                  style={{ color: "#fff !important" }}
                >
                  Encontré...
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Preview;
