import React from "react";
import trindingImgThree from "../../assets/images/testUserpets/pet_2.png";
import { CheckIcon, ClockIcon, HeartIcon, StarIcon } from "./Icons";

import "./style.css";

const PetCard = ({ data, onClick }) => {
  const specials = [
    "Discounted deals",
    "Free Cancellations",
    "New on Entrada",
    "Entrada Specials",
  ];
  return (
    <div class="pet_card wallet" onClick={onClick}>
      <div class="pet_overlay"></div>
      <div class="circle">
        <img src={data?.animal?.pet_image} alt="" />
      </div>

      <div className="pet_name">
        {data.animal?.f_name} {data.animal?.l_name}
      </div>
      <div className="card_info">
        <div className="text_data">
          <h4 className="pet_text">Sexo</h4>
          <h5 className="pet_data">
            {data?.animal?.sex == "male" ? "Macho" : "Hembra"}
          </h5>
        </div>
        <div className="text_data">
          <h4 className="pet_text">¿La mascota está castrada?</h4>
          <h5 className="pet_data">
            {data?.animal?.is_sterillized == "true" ? "Si" : "No"}
          </h5>
        </div>
        <div className="text_data">
          <h4 className="pet_text">Calificación</h4>
          <h5 className="pet_data">{data?.animal?.qualified}</h5>
        </div>
        <div className="text_data">
          <h4 className="pet_text">Species</h4>
          <h5 className="pet_data">{data?.animal?.type}</h5>
        </div>
      </div>
    </div>
  );
};

export default PetCard;
