import "./style.css";

export default function AboutBanner({ loading, aboutPage }) {
  // const handleScroll = () => {
  //   window.scrollBy({
  //     top: 500,
  //     left: 0,
  //     behavior: "smooth", // Optional for smooth scrolling
  //   });
  // };

  return (
    <div
      className="about_banner_conrainer"
      style={{ backgroundImage: `url(${aboutPage?.banner_image})` }}
    >
      <div className="about_banner_text">
        {/* {<div dangerouslySetInnerHTML={{ __html: aboutPage?.banner_title }} />} */}

        {<div dangerouslySetInnerHTML={{ __html: aboutPage?.banner_text }} />}
      </div>

      {/* <div className="scroll_arrow" onClick={() => handleScroll()}>
        {arrowInblack}
      </div> */}
    </div>
  );
}

export const arrowInblack = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="icon-chevron-circle-down"
    viewBox="0 0 44.025 45.36"
  >
    <path d="M20.872.017L23.6.16l-.001.014c1.092.085.455.025 1.91.206l.652.095 1.956.277c.14.064.272.144.393.239.194.169.431.28.685.32 1.232.259 4.196 1.309 4.225 1.323a15.4 15.4 0 014.827 3.428 30.935 30.935 0 013.58 4.957 24.292 24.292 0 012.006 6.508c.203 1.907.245 3.828.125 5.743-.019.36-.041.766-.076 1.315l-.013.23a15.502 15.502 0 01-.443 3.628v.008c-.058.251-.129.548-.205.916-.071.326-.131.604-.181.847a11.332 11.332 0 01-1.376 3.758c-.32.657-.487 1.076-.621 1.417-.347.891-.424 1.079-2.398 3.062a18.565 18.565 0 01-5.422 4.216l-.815.478a17.376 17.376 0 01-9.255 2.198l-2.729-.143.001-.014c-1.091-.085-.454-.025-1.91-.206l-.651-.095-1.957-.277a1.946 1.946 0 01-.393-.239 1.359 1.359 0 00-.684-.32c-1.232-.259-4.196-1.308-4.225-1.323a15.412 15.412 0 01-4.828-3.427 30.912 30.912 0 01-3.579-4.957 24.314 24.314 0 01-2.006-6.508 34.013 34.013 0 01-.125-5.744c.019-.36.04-.766.076-1.315l.012-.23c.005-1.223.154-2.44.444-3.628v-.008c.057-.25.129-.548.204-.916.071-.326.132-.603.182-.847a11.347 11.347 0 011.375-3.757c.321-.657.487-1.076.621-1.418.348-.891.424-1.079 2.399-3.061a18.532 18.532 0 015.422-4.216l.815-.478A17.365 17.365 0 0120.872.017z"></path>
    <path
      fill="#FFF"
      d="M11.92 15.205c.239-.022.479-.018.717.013.275.063.555.1.836.112.424.039.812.254 1.071.591.259.278.498.573.716.884 2.347 3.556 5.279 6.736 7.818 10.167l.071.098c.604-1.118 1.117-2.092 1.114-2.137a.447.447 0 01.088-.256c1.627-2.326 3.242-4.659 4.979-6.896a1.71 1.71 0 01.262-.21l1.286-1.873a1.508 1.508 0 011.121-.832c.186.054.387.02.545-.093 1.398-.679 1.302.116 1.681 1.47.082.225.139.458.169.695.012.282.05.562.113.838.071.419-.036.848-.293 1.186a8.05 8.05 0 01-.668.92c-2.828 3.188-5.14 6.841-7.798 10.184q-.562.701-1.172 1.35c-.212.227-.498.468-.711.334-.267-.173-1.14.084-1.21-.077a.64.64 0 00-.198-.282 1.065 1.065 0 01-.125-.053c-.282-.049-.601.287-.891-.203a.878.878 0 01-.099-.677c-1.311-1.27-3.528-3.362-3.603-3.38a.444.444 0 01-.224-.152c-1.83-2.169-3.667-4.333-5.379-6.591-.055-.097-.1-.2-.135-.307L10.524 18.3a1.511 1.511 0 01-.51-1.299.633.633 0 00.052-.551 2.708 2.708 0 01-.067-.635c.047-.792.752-.593 1.921-.61z"
    ></path>
  </svg>
);
