import axios from "axios";
import React, { useState } from "react";

import { toast } from "react-hot-toast";
import { base_url } from "./../../utils/index";

const useGetAllQualifications = () => {
  const [loading, setLoading] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [originalQualifications, setOriginalQualifications] = useState([]);

  const handleGetAllQualifications = async () => {
    setLoading(true);
    await axios
      .get(`${base_url}/user/calification/get_all`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setQualifications(res?.data?.data);
          setOriginalQualifications(res?.data?.data);
        } else {
          toast.error("There is a problem in qualifications!");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    handleGetAllQualifications,
    qualifications,
    setQualifications,
    originalQualifications,
    setOriginalQualifications,
    loading,
    setLoading,
  };
};

export default useGetAllQualifications;
