import React, { useEffect, useState } from "react";
import "./style.css";
import A_letter from "../../assets/images/a_letter.png";
import fp_dog from "../../assets/images/findPet_dog.png";
import AOS from "aos";
import Modal from "../../components/Modal/Modal";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomInput from "../../components/CustomInput/CustomInput";

import FromGroup from "../../components/FromGroup/FromGroup";
import { FormGroup } from "react-bootstrap";
import axios from "axios";
import { base_url } from "../../utils";
import toast from "react-hot-toast";
import { useMediaQuery } from "./../../components/CustomHooks/useMediaQueries";
import useGetDepProvencia from "../../components/CustomHooks/useGetDepProvencia";
import useGetProvDis from "../../components/CustomHooks/useGetProvDis";
import useGetAllQualifications from "../../components/CustomHooks/useGetAllQualification";
import { ClipLoader } from "react-spinners";
import StartFindPet from "../../components/StrarPage/startFindPet/startFindPet";

const FindPet = () => {
  const getToDayDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate()); // Add 1 day

    // Format the date to YYYY-MM-DD
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, "0");
    const day = String(tomorrow.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const [addLoading, setAddLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [allCalification, setAllCalification] = useState([]);
  const [allDepartmento, setAllDepartment] = useState([]);
  const [allRaza, setAllRaza] = useState([]);
  const [allProvincia, setAllProvincia] = useState([]);
  const [allDistrito, setAllDistrito] = useState([]);
  const [findPetData, setFindPetData] = useState({
    f_name: "",
    l_name: "",
    dob: "",
    sterilized: 0,
    qualification: 0,
    coat_color: "",
    department: "",
    province: "",
    raza: "",
    district: "",
    second_phone: "",
    first_phone: "",
  });
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  const {
    handleGetAllQualifications,
    loading: qualLoading,
    qualifications,
  } = useGetAllQualifications();

  function handleGetAllDepartment() {
    axios
      .get(base_url + `/admins/get_all_departmento_for_admin`)
      .then((res) => {
        if (res.status == 200 && Array.isArray(res.data.Departments)) {
          console.log(res.data.Departments);
          setAllDepartment(res.data.Departments);
        } else {
          console.log("حدث خطأ ما!");
        }
      })
      .catch((e) => console.log(e));
  }

  function handleGetAllRaza() {
    axios
      .get(base_url + `/admins/get_all_raza_for_admin`)
      .then((res) => {
        setAllRaza(res.data?.Raza);
        if (res.status == 200 && Array.isArray(res?.data?.Raza)) {
          setAllRaza(res.data?.Raza);
        } else {
          console.log("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e));
  }

  const {
    handleGetDepProvs,
    loading: depProvLoading,
    depProv,
  } = useGetDepProvencia(findPetData?.department?.value);

  const {
    handleGetProvDis,
    provDis,
    loading: provDisLaoding,
  } = useGetProvDis(findPetData?.province?.value);

  useEffect(() => {
    handleGetAllQualifications();
    handleGetAllDepartment();
    handleGetAllRaza();
  }, []);

  useEffect(() => {
    handleGetDepProvs(findPetData?.department?.value);
  }, [findPetData?.department?.value]);

  useEffect(() => {
    handleGetProvDis(findPetData?.province?.value);
  }, [findPetData?.province?.value]);

  useEffect(() => {
    console.log(findPetData);
  }, [findPetData]);

  function handleSubmit() {
    setAddLoading(true);
    const data_send = {
      ...findPetData,
      sterilized: findPetData?.sterilized?.value,
      qualification: findPetData?.qualification?.value,
      department: findPetData?.department?.value,
      province: findPetData?.province?.value,
      district: findPetData?.district?.value,
      raza: findPetData?.raza?.value,
    };
    axios
      .post(base_url + `/user/create_one`, data_send)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setIsOpenModal(false);
          setFindPetData({
            f_name: "",
            l_name: "",
            dob: "",
            sterilized: 0,
            qualification: 0,
            coat_color: "",
            department: "",
            province: "",
            raza: "",
            district: "",
            second_phone: "",
            first_phone: "",
          });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setAddLoading(false));
  }

  return (
    <>
      <StartFindPet />
    </>
  );
};

export default FindPet;
